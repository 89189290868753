import React from 'react';

import './Footer.scss';

const Footer = () => {
  return (
    <div className="footer">
      <p>
        made by
        <span> webDev </span>
        Ricardo
      </p>
    </div>
  );
};

export default Footer;
